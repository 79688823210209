import { DDMMYYYY, getTimeString } from "./date"

class HostBalance {
	value = null
	color = null
	constructor(data) {
		this.value = data["value"]
		this.color = data["color"]
	}
}

class Host {
	id = null
	name = null
	defaultBackgroundColor = null
	currency = null
	balance = null
	price = null
	constructor(data) {
		this.id = data["id"]
		this.name = data["name"]
		this.price = data["price"]
		this.defaultBackgroundColor = data["defaultBackgroundColor"]
		this.currency = data["currency"]
		if (data["balance"])
			this.balance = new HostBalance(data["balance"])
	}

	nextSlotDateString() {
		let now = new Date()
		return `${now.toLocaleDateString()} ${getTimeString(now)}`
	}
}

export default Host