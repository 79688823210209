<template>
	<div class="host-settings">
		<div class="host-settings__card" v-if="host">
			
			<!-- Host identity -->
			
			<div class="host-identity">
				<div class="host-color" v-bind:style="{
					background: host.defaultBackgroundColor
				}"></div>
				<div class="host-name">{{ host.name }}</div>
			</div>

			<!-- Host state -->
			
			<div class="host-state">
				<div class="host-state__legend">Price ({{ host.currency }})</div>
				<div class="host-state__legend">Balance ({{ host.currency }})</div>
				<div class="host-state__legend">Coming Slot</div>
				<div class="host-state__value important">{{ host.price }}</div>
				<div class="host-state__value important"  v-bind:class="{
					negative : host.balance.value < 0, 
				}">
					{{ host.balance.value }}
				</div>
				<div class="host-state__value">{{ host.nextSlotDateString() }}</div>
			</div>

			<!-- Host hot buttons -->

			<div class="host-hot-buttons buttons">
				<b-button type="is-success" size="is-small" icon-left="arrow-right">Payment</b-button>
				<b-button type="is-light" size="is-small">History</b-button>
			</div>

		</div>

		<div class="host-settings__card" v-if="host">

			<!-- Host stats header -->

			<div class="host-stats__header">
				<div class="host-stats__header-month">
					<div class="host-stats__prev-button">
						<b-icon icon="chevron-left" size="is-small"> </b-icon>
					</div>
					<div class="host-stats__month">{{ monthName }}</div>
					<div class="host-stats__next-button">
						<b-icon icon="chevron-right" size="is-small"> </b-icon>
					</div>
				</div>

				<div class="host-stats__header-close">
					<b-icon icon="close" size="is-small"> </b-icon>
				</div>
			</div>

			<!-- Host stats -->

			<div class="host-state">
				<div class="host-state__legend">Payments ({{ host.currency }})</div>
				<div class="host-state__legend">Balance ({{ host.currency }})</div>
				<div class="host-state__legend">Attended/Planned</div>
				<div class="host-state__value important">
					{{ hostExample.payments }}
				</div>
				<div class="host-state__value important" v-bind:class="{
					negative : hostExample.spending < 0, 
				}">
					{{ hostExample.spending }}
				</div>
				<div class="host-state__value">{{ `${hostExample.plan.attended}/${hostExample.plan.planned}` }}</div>
			</div>

			<div class="host-annotation">
				<a>Penalties were applied. See slot history.</a>
			</div>

		</div>

		<b-tabs type="is-toggle" expanded>
            <b-tab-item label="Slots">
				<div class="slots-container">
					<div class="empty-list-message">
						No slots.
					</div>
				</div>
			</b-tab-item>
            <b-tab-item label="Payments">

			</b-tab-item>
            <b-tab-item label="Details">

			</b-tab-item>
        </b-tabs>
	</div>
</template>

<script>
import Host from '../../scripts/host';
import CMS from '../../service/cms/service';
import { enLongMonths } from '../../scripts/months';

export default {
	name: 'HostSettings',

	computed: {
		hostId(){
			return Number(this.$route.params.hostId)
		},
		hostExample(){
			return {
				payments: 0,
				spending: -60000,
				plan: {
					planned: 7,
					attended: 6,
				}
			}
		},
		host(){
			if (!this.hostId || !Number(this.hostId))
				return null
			let host = this.$store.getters.calendarSettingsHostById(this.hostId) 
			return host
		},
		monthName(){
			return enLongMonths[new Date().getMonth()]
		}
	},

	created() {
		if (this.$store.getters.calendarSettingsHosts.length == 0) {
			let calendarUid = this.$route.params.calendarUid
			CMS.settings.getHosts(calendarUid)
			.then((data) => {
				if (data && data.err)
					throw data.err
				if (!data)
					return
				let hosts = Array.from(data.hosts)
					.map(hostData => new Host(hostData))

				this.$store.dispatch('update-settings-hosts', hosts)
			})
			.catch(_ => {})
		}
	},


	data() {
		return {
		
		};
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style scoped>

.host-settings{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.host-settings__card{
	width: 100%;
	border: 1px solid lightgrey;
	border-radius: 14px;

	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 12px;
}

.host-identity{
	display: flex;
	gap: 10px;
	align-items: center;
	padding-bottom: 6px;
	border-bottom: 1px solid rgb(231, 231, 231);
}

.host-name{
	font-size: 18px;
	font-weight: 500;
}

.host-color{
	width: 22px;
	height: 22px;
	border-radius: 2px;
}
.host-state{
	width:100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr) auto;
	/* gap: 6px; */
}
.host-state__legend{
	font-size: 12px;
	color: rgb(189, 189, 189);
}
.host-state__value{
	font-size: 14px;
}
.host-state__value.important{
	font-weight: 500;
}
.host-state__value.negative{
	color: #f14668;
}

.host-stats__header{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid rgb(231, 231, 231);
	padding-bottom: 6px;
}
.host-stats__header-month{
	display: flex;
	gap: 10px;
	align-items: center;
}

.host-annotation{
	font-size: 12px;
}

.empty-list-message{
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>