import { render, staticRenderFns } from "./HostSettings.vue?vue&type=template&id=8f0c0e32&scoped=true&"
import script from "./HostSettings.vue?vue&type=script&lang=js&"
export * from "./HostSettings.vue?vue&type=script&lang=js&"
import style0 from "./HostSettings.vue?vue&type=style&index=0&id=8f0c0e32&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f0c0e32",
  null
  
)

export default component.exports